
import React from 'react';
import {Link} from 'react-router-dom';

import PageBanner from './../layouts/PageBanner';

//images
import bgImage from './../assets/images/banner/bnr1.jpg';
import pic1 from './../assets/images/main-slider/slider2/pic1.png';
import about from './../assets/images/about/pic1.jpg';
import bg4 from './../assets/images/background/bg4.jpg';

//import shape1 from './../assets/images/pattern/shape1.png';
//import shape2 from './../assets/images/pattern/shape2.png';
//import shape3 from './../assets/images/pattern/shape3.png';
//import shape5 from './../assets/images/pattern/shape5.png';
//import shape6 from './../assets/images/pattern/shape6.png';
// import NewsSlider from '../components/Home/NewsSlider';
import UpdateBlog from '../components/Home/UpdateBlog';
import OurMission from '../components/OurMission';
import { IMAGES } from '../constant/theme';
// import { color } from 'chart.js/helpers';

const iconBlog = [
    {title:"Mission and Vision"},

];

const wrapperBlog = [
    {icon:"flaticon-vr-glasses", title:"Comprehensive Support"},
    {icon:"flaticon-transformation", title:"Deep Local Impact"},
    {icon:"flaticon-doctor-bag", title:"Transparency and Accountability"},
    {icon:"flaticon-open-book", title:"Community Engagement:"},
];


const AboutUs = () => {
    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="Home" pagetitle="About Us" background={bgImage} />
                <section className="content-inner section-wrapper5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="section-head">
                                    <h5 className="sub-title">Who We Are</h5>
                                    <h2>ABOUT BASOVCA</h2>
                                    <p className="m-t20">The Bauchi State Orphans and Vulnerable Children Agency was established by the Bauchi State Government through a House of Assembly Bill on October 22, 2012. Our agency is entrusted with the responsibility to coordinate the entire response to the needs of orphans and vulnerable children (OVC) in Bauchi State. </p>
                                </div>
                                {iconBlog.map((data, index)=>(
                                    <div className="icon-bx-wraper box-hover style-2 m-b30" key={index}>
                                        <div className="icon-lg"> 
                                            <Link to={"/services-details"} className="icon-cell">
                                                <i className="flaticon-shield"></i>
                                            </Link> 
                                        </div>
                                        <div className="icon-content">
                                        <h5 className="dz-tilte m-b5 text-capitalize">{data.title}</h5>

                                            <p>
                                                <p>Mission</p>
                                            Equitable access to, and benefit from, sufficient quality and sustainable care services through social protection and support framework for effective and efficient service delivery.  
                                             </p>
                                             <p>
                                             <p>Our vision</p>
                                             Sustainable OVC access to sufficient basic social services in Health, Food, Education, and Social Justice in Bauchi State.
                                             </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-lg-5 m-b30">
                                <div className="dz-media">
                                    <img src={pic1} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-inner-2 bg-light section-pattren1">
                    <div className="container">
                        <div className="row about-bx3 align-items-center">
                            <OurMission />
                        </div>
                    </div>
                    <img src={IMAGES.Shape1} className="shape-1 move-1" alt="shape" />
                    <img src={IMAGES.Shape2} className="shape-2 move-2" alt="shape" />
                    <img src={IMAGES.Shape3} className="shape-3 move-1" alt="shape" />
                    <img src={IMAGES.Shape5} className="shape-4 rotating" alt="shape"/>
                    <img src={IMAGES.Shape6} className="shape-5 rotating" alt="shape"/>
                    <img src={IMAGES.Shape6} className="shape-6 rotating" alt="shape"/>
                </section>
                
                <section className="content-inner"> 
                    <div className="container">
                        <div className="section-head text-center">
                            <h5 className="sub-title">OUR VALUES</h5>
                     
                        
                        </div>
                        <div className="row">
                            {wrapperBlog.map((item, ind)=>(
                                <div className="col-lg-6 col-md-12 col-sm-12" key={ind}>
                                    <div className="icon-bx-wraper box-hover style-2 m-b30">
                                        <div className="icon-lg"> 
                                            <Link to={"/services-details"} className="icon-cell">
                                                <i className={item.icon}></i>
                                            </Link> 
                                        </div>
                                        <div className="icon-content">
                                            <h5 className="dz-tilte m-b10 text-capitalize">{item.title}</h5>
                                            <p>We offer a holistic approach that includes education, healthcare, and emotional support, ensuring that every child receives the care and resources they need to succeed.

</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
               
               
                <section className="content-inner-2 overlay-primary-dark about-wrapper2 bg-img-fix" style={{backgroundImage:"url("+ bg4 +")",  backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                    <div className="container">
                        <div className="about-bx2">
                            <div className="row g-0">
                                <div className="col-lg-4">
                                    <div className="dz-media">
                                        <img src={about} alt="image" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="dz-info">
                                        <h2 className="title m-b30 m-md-b20">About Haj.Yalwa T Balewa</h2>
                                        <h5 className="title m-b30 m-md-b20">The Executive Chairperson Basovca Bauchi</h5>
                                     
                                        <div className="signature">
                                            
                                            <p className="text-dark m-t15 mb-0"> The Executive Chairperson of the Bauchi State Orphans and Vulnerable Children Agency (BASOVCA) is a dedicated leader focused on improving the lives of orphans and vulnerable children in Bauchi State. With a background in child welfare and community development, the Chairperson is committed to creating a safe environment where every child can thrive.
Under their leadership, BASOVCA has strengthened its efforts in providing essential services like education, healthcare, and psychosocial support. Their strategic approach and passion for the cause have been instrumental in driving the agency’s mission forward.
</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               
                <div className="call-action style-1 content-inner-1">
                    <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>
        </>
    );
};


export default AboutUs;