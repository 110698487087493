import React from 'react';
// import {Link} from 'react-router-dom';

//images
import pic from './../../assets/images/project/large/pic1.jpg';
import avatar from './../../assets/images/logo.png';

const StoryBlog = () => {
    function isScrolledIntoView(elem){
        const spliBox = document.querySelectorAll(elem);
        
        spliBox.forEach(myFunction);
        function myFunction(item) {

            const docViewTop = window.scrollY;

            const docViewBottom = docViewTop + window.innerHeight;

            let elemTop = item.getBoundingClientRect().top + docViewTop;

            const elemBottom = elemTop + item.offsetHeight;

            if((elemBottom <= docViewBottom) && (elemTop >= docViewTop)){               
                item.classList.add('split-active');
            }
          }
    }
    window.addEventListener("scroll", () => {
        isScrolledIntoView('.split-box');
    });   
    return(
        <>
           
          
            <div className="row align-items-center project-bx left m-b30">
                <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.2s">	
                    <div 
                        
                        //className={`dz-media split-box ${abc ? "split-active" : ""}`} 
                        className={`dz-media split-box`} 
                        //id="content"
                    >
                        <div>
                            <img src={pic} alt="" className="app-1" />
                        </div>
                    </div> 
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="dz-content wow fadeInUp" data-wow-delay="0.4s">
                        <h3 className="title m-b15">EMPOWERNMENT TO HHS 2023 AND 2022</h3>
                        <p className="m-b0">
                        The Bauchi State Orphans and Vulnerable Children Agency successfully conducted empowerment programs for the HHS 2023 and 2022. These initiatives have significantly impacted the lives of many, providing essential skills and support to help them build a better future. Stay tuned for more updates on our ongoing efforts to uplift and empower the most vulnerable in our community.
                        </p>
                        <div className="fund-count m-t30 m-md-t20">
                            <div className="icon-content">
                                <h2 className="text-primary"> <span className="counter">25-07-2024</span></h2>
                                <h6>Bauchi State</h6>
                            </div>
                        </div>
                        <div className="author-wrappper">
                            <div className="author-media">
                                <img src={avatar} alt="" /> 
                            </div>
                            <div className="author-content">
                                <div className="author-head">
                                    <h6 className="author-name">Basovca</h6>
                                    <ul className="rating-list">
                                        <li><i className="fa fa-star"></i></li>
                                        <li><i className="fa fa-star"></i></li>
                                        <li><i className="fa fa-star"></i></li>
                                        <li><i className="fa fa-star gray-light"></i></li>
                                        <li><i className="fa fa-star gray-light"></i></li>
                                    </ul>
                                </div>
                                <ul className="author-meta">
                                   
                                    <li className="location">Bauchi State, Nigeria</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>               


            {/* <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.2s">	
                <div                     
                    className={`dz-media split-box`} 
                   // id="content"
                >
                    <div>
                        <img src={pic} alt="" className="app-1" />
                    </div>
                </div> 
            </div>
            <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.2s">	
                <div                     
                    className={`dz-media split-box`} 
                   // id="content"
                >
                    <div>
                        <img src={pic} alt="" className="app-1" />
                    </div>
                </div> 
            </div> */}
        </>
    )
}
export default StoryBlog;