export const MenuListArray2 = [
  {
    title: "Home",
    to: "/",
  },
  {
    title: "About",
    to: "/about-us",
  },
  //     {

  // content : [

  // {
  //     title : "Volunteer",
  //     to : "#",
  //     className:"sub-menu",
  //     content : [
  //         {
  //             title:"Volunteer",
  //             to:"/volunteer"
  //         },
  //         {
  //             title:"Become A Volunteer",
  //             to:"/become-a-volunteer"
  //         },
  //     ],
  // },
  // {
  //     title : "Faq",
  //     to : "/faq"
  // },
  // {
  //     title : "Certificates",
  //     to : "/certificates"
  // },
  // {
  //     title : "Ask A Question",
  //     to : "/ask-a-question"
  // },
  // {
  //     title : "Happy Clients",
  //     to : "/happy-clients"
  // },
  // {
  //     title : "How It Works",
  //     to : "/how-it-works"
  // },
  // {
  //     title : "Mission",
  //     to : "/mission"
  // },

  //     ],
  // },
  // {
  //     title:"Fundraiser",
  //     classChange:"sub-menu-down",
  //     content : [
  //         {
  //             title:"Browse Fundraiser",
  //             to:"/browse-fundraiser"
  //         },
  //         {
  //             title:"Become A Fundraiser",
  //             to:"/become-a-fundraiser"
  //         },
  //         {
  //             title:"Fundraiser Detail",
  //             to:"/fundraiser-detail"
  //         },
  //     ],
  // },
  {
    title: "Projects",
    to: "/project",
  },
  {
    title: "Team",
    to: "/team-members",
  },
  // {
  //     title:"News",
  //       to:"/blog",

  // },
  {
    title: "Contact Us",
    to: "./contact-us",
  },
];